import { createApi } from "@reduxjs/toolkit/query/react";

import { notificationBaseQuery } from "./notificationBaseQuery";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: notificationBaseQuery(),
  endpoints: () => ({}),
  tagTypes: ["Notification"],
});
