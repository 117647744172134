import { createSlice } from "@reduxjs/toolkit";

const layoutInitialState = {
  open: true,
  isMessageBoxOpen: false,
  talentId: null,
};

export const layoutSlice = createSlice({
  name: "layoutSlice",
  initialState: layoutInitialState,
  reducers: {
    openMessageBox: (state) => {
      state.isMessageBoxOpen = true;
    },
    closeMessageBox: (state) => {
      state.isMessageBoxOpen = false;
    },
    setTalentId: (state, action) => {
      state.talentId = action.payload;
    },
  },
});

export const { setTalentId, openMessageBox, closeMessageBox } =
  layoutSlice.actions;
export const messageBoxStatus = (state) => state.layout.isMessageBoxOpen;
export const talentIdValue = (state) => state.layout.talentId;
