import React from "react";
import { Navigate } from "react-router-dom";

import { useSessionContext } from "store/session-context";

const UnProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useSessionContext();

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default UnProtectedRoute;
