import { useEffect } from "react";
import { ThemeProvider } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { clearFollowUpLocalStorage } from "components/StatisticFollowUp/utils/utils";

const LayoutWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/follow-up") {
      clearFollowUpLocalStorage();
    }
  }, [location]);

  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      {children}
    </ThemeProvider>
  );
};

export default LayoutWrapper;
