export const TALENT_CONTACT_TYPES = [
  { id: "candidate", label: "Candidate" },
  { id: "client", label: "Client" },
  { id: "prospect", label: "Prospect" },
  { id: "provider", label: "Provider" },
];

export const TALENT_CONTACT_VALUES = [
  { id: "candidate", label: "Candidate", value: 0 },
  { id: "client", label: "Client", value: 1 },
  { id: "prospect", label: "Prospect", value: 2 },
  { id: "provider", label: "Provider", value: 3 },
];
