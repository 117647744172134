import { useContext } from "react";

import SessionContext from "store/session-context";

export const useAuthProtectedRoute = () => {
  const sessionCtx = useContext(SessionContext);

  const isAuthenticated = sessionCtx?.isLoggedIn;

  return {
    isAuthenticated,
  };
};
