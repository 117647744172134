import { Navigate } from "react-router-dom";

import { useAuthProtectedRoute } from "hooks/useAuthProtectedRoute";

import Layout from "../Layout";

const LAYOUT_TYPE = {
  ASSIGNMENT_MANAGEMENT: "ASSIGNMENT_MANAGEMENT",
  FOLLOW_UP: "FOLLOW_UP",
  DEFAULT: "DEFAULT",
};

export const ProtectedRoute = ({
  children,
  layoutType = LAYOUT_TYPE.DEFAULT,
}) => {
  const { isAuthenticated } = useAuthProtectedRoute();

  if (!isAuthenticated) return <Navigate replace to="/login" />;

  // Assignment pipe has custom layout due to react beautiful dnd does not support nested scroll. So we check the url in order to know if we are in the pipe.
  if (layoutType === LAYOUT_TYPE.ASSIGNMENT_MANAGEMENT) {
    return (
      <Layout.AssignmentManagement>{children}</Layout.AssignmentManagement>
    );
  }

  if (layoutType === LAYOUT_TYPE.FOLLOW_UP) {
    return <Layout.FollowUp>{children}</Layout.FollowUp>;
  }

  return <Layout.Default>{children}</Layout.Default>;
};
