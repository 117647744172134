import { lazy } from "react";
import { withErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "components/ErrorFallback/ErrorFallback";

// Login
export const Login = lazy(() => import("pages/Login"));

// Home
export const Home = withErrorBoundary(
  lazy(() => import("pages/Home")),
  { FallbackComponent: ErrorFallback },
);

// Assignment
export const Assignments = withErrorBoundary(
  lazy(() => import("pages/Assignments/Lists")),
  {
    FallbackComponent: ErrorFallback,
  },
);

export const AssignmentCreate = withErrorBoundary(
  lazy(() => import("pages/Assignments/CreateAssignment")),
  {
    FallbackComponent: ErrorFallback,
  },
);

export const CreateAssignment = lazy(() =>
  import("pages/Assignments/CreateAssignmentV2"),
);

export const AssignmentManagement = withErrorBoundary(
  lazy(() => import("pages/Assignments/Management")),
  {
    FallbackComponent: ErrorFallback,
  },
);
export const AssignmentGenerateTalentDescription = withErrorBoundary(
  lazy(() => import("pages/Assignments/TalentDescription")),
  {
    FallbackComponent: ErrorFallback,
  },
);
export const AssignmentTimeline = withErrorBoundary(
  lazy(() => import("pages/Assignments/Timelines")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// Matche
export const Matche = withErrorBoundary(
  lazy(() => import("pages/Matche/Matche")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// Statistic Follow Up
export const StatisticFollowUp = lazy(() =>
  import("pages/StatisticFollowUp/StatisticFollowUp"),
);
export const FollowUpMyTeams = lazy(() =>
  import("components/StatisticFollowUp/MyTeams/FollowUpMyTeams"),
);
export const ConsultantStatistic = lazy(() =>
  import(
    "components/StatisticFollowUp/ConsultantStatistic/ConsultantStatistic"
  ),
);
export const TeamStatistic = lazy(() =>
  import("components/StatisticFollowUp/TeamStatistic/TeamStatistic"),
);
export const FollowUp = lazy(() =>
  import("components/StatisticFollowUp/FollowUp/FollowUp"),
);

// Directory
export const Directory = withErrorBoundary(
  lazy(() => import("pages/Directory/Directory")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// KPI
export const Kpi = withErrorBoundary(
  lazy(() => import("pages/Kpi/Kpi")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// Document
export const Document = withErrorBoundary(
  lazy(() => import("pages/Document/Document")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// Company
export const CompanyList = withErrorBoundary(
  lazy(() => import("pages/Company/List/List")),
  {
    FallbackComponent: ErrorFallback,
  },
);
export const CompanyDetails = withErrorBoundary(
  lazy(() => import("pages/Company/Details")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// Talent
export const TalentList = withErrorBoundary(
  lazy(() => import("pages/Talent/List/")),
  {
    FallbackComponent: ErrorFallback,
  },
);
export const TalentDetails = withErrorBoundary(
  lazy(() => import("pages/Talent/TalentContentsPage")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// User
export const UserProfile = withErrorBoundary(
  lazy(() => import("pages/Profile")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// Notification
export const Notifications = withErrorBoundary(
  lazy(() => import("pages/Notification")),
  {
    FallbackComponent: ErrorFallback,
  },
);

// 404 Not found page
export const NotFound = lazy(() => import("pages/NotFound/"));

// Invoicing
export const Invoicing = withErrorBoundary(
  lazy(() => import("pages/Invoicing")),
  {
    FallbackComponent: ErrorFallback,
  },
);
