/**
 * @namespace assignment
 */

/**
 * Enumeration for different types of assignments.
 * @enum {string}
 */
export const ASSIGNMENT_TYPES = {
  /** Unknown assignment type */
  UNKNOWN: "unknown",
  /** Executive search assignment */
  EXECUTIVE_SEARCH: "executive_search",
  /** Interim management assignment */
  INTERIM_MANAGEMENT: "interim_management",
  /** Leadership advisory assignment */
  LEADERSHIP_ADVISORY: "leadership_advisory",
};

/**
 * Enumeration for assignment status values.
 * @enum {number}
 */
export const ASSIGNMENT_STATUS = {
  /** Assignment has been lost */
  LOST: 3,
  /** Assignment has been closed */
  CLOSED: 2,
  /** Assignment is in progress */
  IN_PROGRESS: 1,
  /** Assignment has been interrupted */
  INTERRUPTED: 4,
  /** Assignment is in the prospecting stage */
  PROSPECTING: 0,
};

/**
 * List of Kanban columns for assignment tracking.
 * @type {Object[]}
 * @property {string} id - The unique identifier for the Kanban column.
 * @property {string} title - The title of the Kanban column.
 *
 */
export const ASSIGNMENT_KANBAN_COLUMNS = [
  {
    id: "identified",
    title: "Identified",
  },
  {
    id: "qualified",
    title: "Qualified",
  },
  {
    id: "lincoln_interview",
    title: "Lincoln Interview",
  },
  {
    id: "short_listed",
    title: "Short Listed",
  },
  {
    id: "client_interview",
    title: "Client Interview",
  },
  {
    id: "in_offer",
    title: "In Offer",
  },
  {
    id: "hired",
    title: "Recruited",
  },
];

/**
 * Enumeration for Kanban column states.
 * @enum {string}
 */
export const KANBAN_COLUMN_STATES = {
  /** Candidate has been qualified */
  QUALIFIED: "qualified",
  /** Candidate is short-listed */
  SHORT_LISTED: "short_listed",
  /** Candidate has a Lincoln interview */
  LINCOLN_INTERVIEW: "lincoln_interview",
  /** Candidate is in the offer stage */
  IN_OFFER: "in_offer",
  /** Candidate has a client interview */
  CLIENT_INTERVIEW: "client_interview",
  /** Candidate has been recruited/hired */
  RECRUITED: "hired",
  /** Candidate is identified */
  IDENTIFIED: "identified",
};

/**
 * Maximum character limits for various assessment sections.
 * @type {number}
 */
export const MAX_CHAR_STRENGTH = 2200;

/**
 * Maximum character limit for attention section.
 * @type {number}
 */
export const MAX_CHAR_ATTENTION = 600;

/**
 * Maximum character limit for project section.
 * @type {number}
 */
export const MAX_CHAR_PROJECT = 450;

/**
 * Maximum number of lines for the strength assessment section.
 * @type {number}
 */
export const ASSESSMENT_STRENGTH_MAX_LINE = 20;

/**
 * Maximum number of lines for the attention assessment section.
 * @type {number}
 */
export const ASSESSMENT_ATTENTION_MAX_LINE = 5;

/**
 * Maximum number of lines for the motivation assessment section.
 * @type {number}
 */
export const ASSESSMENT_MOTIVATION_MAX_LINE = 9;

/**
 * An object representing the perimeter values.
 *
 * @constant {Object}
 * @property {string} global - Represents a global perimeter value.
 * @property {string} partial - Represents a partial perimeter value.
 */
export const ASSIGNMENT_PERIMETER = {
  global: "global",
  partial: "partial",
};
