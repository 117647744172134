/** * @module sentry */

import * as Sentry from "@sentry/react";

import { SENTRY_URL_TAGS_BY_MODULE } from "utils/constants/sentry/module";

/**
 * Retrieves the Sentry module tag based on the current window location pathname.
 *
 * @returns {string} The Sentry module tag corresponding to the current URL pathname.
 *                   Returns 'NOT_SPECIFIED' if the module tag cannot be determined.
 *
 * @example
 * const moduleTag = getModuleTagForSentry();
 * console.log(moduleTag); // Output: Returns a module tag based on the current URL pathname
 */
function getModuleTagForSentry() {
  const { pathname } = window.location;

  if (pathname.includes("assignments/manage"))
    return SENTRY_URL_TAGS_BY_MODULE.ASSIGNMENT_MANAGEMENT;

  if (pathname.includes("assignments/create"))
    return SENTRY_URL_TAGS_BY_MODULE.ASSIGNMENT_CREATION;

  if (pathname.includes("directory"))
    return SENTRY_URL_TAGS_BY_MODULE.DIRECTORY;

  if (pathname.includes("document")) return SENTRY_URL_TAGS_BY_MODULE.DOCUMENT;

  if (pathname.includes("company")) return SENTRY_URL_TAGS_BY_MODULE.COMPANY;

  if (pathname.includes("matche")) return SENTRY_URL_TAGS_BY_MODULE.MATCHE;

  if (pathname.includes("assignments/generate-job-description")) {
    return SENTRY_URL_TAGS_BY_MODULE.TALENT_DESCRIPTION;
  }

  return SENTRY_URL_TAGS_BY_MODULE.NOT_SPECIFIED;
}

/**
 * Sends feedback and error information to Sentry.
 *
 * @param {Object} params - The parameters object containing session, error, and data.
 * @param {Object} params.session - The session object containing user information.
 * @param {Object} params.error - The error object or response containing error details.
 * @param {Object} params.data - Additional data related to the feedback or error.
 * @returns {void}
 *
 * @example
 * const session = { user: { id: 123, email: 'example@example.com', firstname: 'John', lastname: 'Doe' } };
 * const error = new Error('Failed to load data');
 * const data = { endpoint: '/api/data', method: 'GET' };
 * sendFeedbackToSentry({ session, error, data });
 */
export function sendFeedbackToSentry({ session, error, data }) {
  const moduleName = getModuleTagForSentry();

  const sentryErrorMessage = `Module ${moduleName}: ${
    error.response?.data?.message || "Error while making request"
  }`;

  Sentry.setUser({
    id: session.user.id,
    email: session.user.email,
    name: `${session.user.firstname} ${session.user.lastname}`,
  });

  Sentry.setTag("module", moduleName);

  Sentry.addBreadcrumb({
    level: "info",
    message: "payload",
    data: { ...data },
  });

  Sentry.captureMessage(sentryErrorMessage);
  Sentry.captureException(error);
}
