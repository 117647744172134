import axios from "axios";

import ExtjsStateManager from "utils/extjs-state-manager";
import { showToastOnError } from "utils/helpers/assignment";

export const chatBaseQuery =
  () =>
  async ({ url, method, data, params }) => {
    try {
      const chatToken =
        ExtjsStateManager.get("ext-session").connect.api_chat_key;
      const tokenType = { Authorization: `Bearer ${chatToken}` };
      let contentType = {};

      contentType = { "Content-Type": "application/json" };

      const result = await axios({
        url,
        method,
        data,
        params,
        headers: {
          Accept: "application/json",
          ...contentType,
          ...tokenType,
        },
      });

      if (
        result.data &&
        result.data["success"] !== undefined &&
        !result.data.success
      ) {
        throw new Error();
      }
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      if (err.code === "ERR_NETWORK") {
        showToastOnError("Network Error");
      } else if (
        (err.response && err.response?.status === 403) ||
        (err.response && err.response?.status === 401)
      ) {
        window.open(`${window.location.origin}/login`, "_self");
      } else {
        showToastOnError(
          "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
        );
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
