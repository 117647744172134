/** * @module object */

import _ from "lodash";

/**
 * Converts a proxy object to a plain old JavaScript object (POJO).
 * @param {Object} proxyObj - The proxy object to convert.
 * @returns {Object} The converted POJO.
 *
 * @example
 * // Assuming `proxyObj` is a proxy object.
 * const proxyObj = new Proxy({ key: 'value' }, {});
 * const pojo = convertProxyObjectToPojo(proxyObj);
 * console.log(pojo); // Output: { key: 'value' }
 */
export function convertProxyObjectToPojo(proxyObj) {
  return _.cloneDeep(proxyObj);
}

/**
 * Creates an object representing a content item with a title, key, and value.
 *
 * @param {string} title - The title of the content item.
 * @param {string} key - The key used to identify the content item.
 * @param {string|number} value - The value associated with the content item.
 * @returns {{ title: string, key: string, value: string|number }} - The content item object.
 */
export function createContentItem(title, key, value) {
  return { title, key, value };
}
