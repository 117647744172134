/** * @module link */

/**
 * Checks if one URL path contains another URL path.
 *
 * @param {string} url1 - The first URL path to check.
 * @param {string} url2 - The second URL path to check.
 * @returns {boolean} Returns true if url2 path includes url1 path, otherwise false.
 *
 * @example
 * const url1 = '/talents';
 * const url2 = '/talents/assignment';
 * const contains = containsUrl(url1, url2);
 * console.log(contains); // Output: true (since '/talents/assignment' contains '/talents')
 *
 * const url1NoSlash = 'talents';
 * const url2NoSlash = 'talents/assignment';
 * const containsNoSlash = containsUrl(url1NoSlash, url2NoSlash);
 * console.log(containsNoSlash); // Output: true (same result without leading slashes)
 */
export function containsUrl(url1, url2) {
  const url = new URL(window.location.href);
  const url1Obj = new URL(url1, url.origin);
  const url2Obj = new URL(url2, url.origin);

  return url2Obj.pathname.includes(url1Obj.pathname);
}
