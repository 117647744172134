import { createSlice } from "@reduxjs/toolkit";

const defaultModalState = {
  addActionModal: false,
  editActionModal: false,
  currentSelectedItem: {},
  deleteActionModal: false,
  downloadReferenceModal: false,
};

const TalentInitialState = {
  talentModalState: defaultModalState,
  recentUploadedFileTalent: {
    pdf: [],
  },
  talentSelectedState: {
    talent: {},
  },
  setCurrentSelectedTalentItem: {},
};

export const talentSlice = createSlice({
  name: "talentSlice",
  initialState: TalentInitialState,
  reducers: {
    handleOpenTalentModal: (state, action) => {
      const { type, value } = action.payload;
      state.talentModalState[type] = value;
      return state;
    },
    setRecentUploadedFileTalent: (state, action) => {
      const { type, value } = action.payload;
      state.recentUploadedFileTalent[type].push(value);
      return state;
    },
    setTalentSelectedState: (state, action) => {
      const { type, value } = action.payload;
      state.talentSelectedState[type] = value;
      return state;
    },
    setCurrentSelectedTalentItem: (state, action) => {
      const { item } = action.payload;
      state.talentModalState.currentSelectedItem = item;
      return state;
    },
    resetTalentModalState: (state) => {
      state.talentModalState = defaultModalState;
      return state;
    },
  },
});

export const {
  handleOpenTalentModal,
  resetTalentModalState,
  setTalentSelectedState,
  setRecentUploadedFileTalent,
  setCurrentSelectedTalentItem,
} = talentSlice.actions;
