import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { NavLink } from "react-router-dom";

const NavItem = ({
  children,
  link,
  name = "Directory",
  className,
  target,
  isExternalLink,
}) => {
  const renderTooltip = (props) => (
    <Tooltip id={`${name.slice(0, 4)}_tooltip`} {...props}>
      {name}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      placement="bottom"
      key={`${name.split(" ").join("")}`}
    >
      <NavLink
        to={!isExternalLink && link}
        target={target || "_blank"}
        rel="noreferrer"
        className={className}
        style={{ width: "auto" }}
        onClick={(event) => {
          if (isExternalLink) {
            event.preventDefault();
            window.open(link, target);
          }
        }}
      >
        {children}
      </NavLink>
    </OverlayTrigger>
  );
};

export default NavItem;
