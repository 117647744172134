import { useContext } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

import SessionContext from "store/session-context";

export const GoogleMapsWrapper = ({ children }) => {
  const { googleApiKey } = useContext(SessionContext);

  return (
    <Wrapper apiKey={googleApiKey} libraries={["places"]}>
      {children}
    </Wrapper>
  );
};
