import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import { Invoicing } from "routes/pages.import";

export const invoicing = [
  {
    path: "/invoicing",
    element: (
      <ProtectedRoute>
        <Invoicing />
      </ProtectedRoute>
    ),
  },
];
