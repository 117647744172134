import { createSlice } from "@reduxjs/toolkit";

const defaultBoardModalState = {
  reminderModal: false,
  ndaActionModal: false,
  negativeAnswer: false,
  currentSelectedCard: {},
  deleteActionModal: false,
  takeReferenceModal: false,
  isLincolnInterview: false,
  sendTaleMeMoreModal: false,
  clientInterviewModal: false,
  setContractInformation: false,
  generateClientContact: false,
  setOnboardingDateModal: false,
  rejectShortListedModal: false,
  downloadReferenceModal: false,
  generateAgreementLetter: false,
  isJobOfferOpenFromButton: false,
  downloadTalentReportModal: false,
  taleMeMoreDebriefingModal: false,
  identifiedToQualifiedModal: false,
  generateContractInformation: false,
  clientInterviewToInOfferModal: false,
  qualifiedToLincolnInterviewModal: false,
  shortListedToClientInterviewModal: false,
  identifiedToLincolnInterviewModal: false,
  lincolnInterviewToShortListenedModal: false,
  isFromlincolnInterviewToShortListenedModal: false,
};

const kanbanInitialState = {
  boardState: [],
  backupState: [],
  talents: [],
  boardModalState: defaultBoardModalState,
  missionDetails: {
    editMission: false,
    createAction: false,
  },
  actionSource: {
    source: "",
  },
  kanbanCardTotal: 0,
};

export const kanbanSlice = createSlice({
  name: "kanbanSlice",
  initialState: kanbanInitialState,
  reducers: {
    handleOpenKanbanModal: (state, action) => {
      const { type, value } = action.payload;
      state.boardModalState[type] = value;
      return state;
    },
    initiateKanbanModalState: (state) => {
      state.boardModalState = defaultBoardModalState;
      return state;
    },
    setTalents: (state, action) => {
      const { value } = action.payload;
      state.talents = value;
      return state;
    },
    setBackUpState: (state, action) => {
      const { value } = action.payload;
      state.backupState = value;
      return state;
    },
    resetKanbanState: () => {
      return kanbanInitialState;
    },
    setKanbanCardTotal: (state, action) => {
      const { kanbanCardTotal } = action.payload;
      state.kanbanCardTotal = kanbanCardTotal;
      return state;
    },
    setCurrentSelectedCard: (state, action) => {
      const { item } = action.payload;
      state.boardModalState.currentSelectedCard = item;
      return state;
    },
    handleOpenMissionDetailsModal: (state, action) => {
      const { type, value } = action.payload;
      state.missionDetails[type] = value;
      return state;
    },
    setActionSource: (state, action) => {
      const { type, value } = action.payload;
      state.actionSource[type] = value;
      return state;
    },
  },
});

export const {
  moveItem,
  setTalents,
  setBackUpState,
  setActionSource,
  resetKanbanState,
  setKanbanCardTotal,
  handleOpenKanbanModal,
  setCurrentSelectedCard,
  initiateKanbanModalState,
  setRecentUploadedFileTalent,
  handleOpenMissionDetailsModal,
} = kanbanSlice.actions;
