import UnProtectedRoute from "components/UnprotectedRoute/UnprotectedRoute";
import { Login } from "routes/pages.import";

export const login = [
  {
    path: "/login",
    element: (
      <UnProtectedRoute>
        <Login />
      </UnProtectedRoute>
    ),
  },
];
