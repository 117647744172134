export const FollowUpIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="FollowUpIcon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6883 16.7657C15.5321 16.8595 15.3577 16.909 15.1801 16.9091C15.0366 16.9093 14.8944 16.877 14.7618 16.8142C14.6292 16.7514 14.5088 16.6593 14.4074 16.5431L9.62069 11.0727L3.04943 17.648C2.93807 17.7597 2.80575 17.8483 2.66006 17.9087C2.51438 17.9691 2.3582 18.0002 2.20049 18C1.96305 17.9999 1.73094 17.9295 1.53353 17.7974C1.33611 17.6654 1.18225 17.4778 1.09139 17.2583C1.00053 17.0388 0.976749 16.7972 1.02306 16.5642C1.06937 16.3312 1.18369 16.1171 1.35156 15.9491L8.55607 8.74022C8.69644 8.60456 8.86634 8.50646 9.05022 8.45213C9.2399 8.28024 9.47529 8.18429 9.71973 8.18187C10.0063 8.17902 10.2823 8.30491 10.4884 8.53241L15.2887 14.0183L22.9651 6.33727C23.1915 6.11841 23.4948 5.99731 23.8097 6.00005C24.1245 6.00278 24.4257 6.12914 24.6483 6.3519C24.8709 6.57467 24.9972 6.87601 25 7.19104C25.0027 7.50606 24.8817 7.80955 24.6629 8.03616L16.2577 16.4465C16.1463 16.5582 16.014 16.6468 15.8683 16.7073C15.8098 16.7315 15.7496 16.751 15.6883 16.7657Z"
      fill="white"
    />
  </svg>
);
