export const LinkonLogo = () => (
  <svg
    width="43"
    height="49"
    viewBox="0 0 43 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="LinkonLogoId"
  >
    <path
      d="M19.3274 0V2.14677C19.3274 9.95685 19.2733 17.7669 19.3587 25.577C19.3872 28.0432 19.6379 30.5295 20.0537 32.9612C21.1019 39.108 29.6297 39.8706 33.0846 36.4087C36.0611 33.4274 37.4026 29.7641 37.7045 25.7267C38.371 16.7943 33.4891 9.63455 25.0525 6.89497C24.0271 6.56116 22.9447 6.42015 21.7883 6.16692V4.26188C23.9843 4.70792 26.1519 4.90936 28.1542 5.59713C37.189 8.69642 41.9542 15.3065 42.8884 24.7454C44.0163 36.1526 36.4485 45.8217 26.6959 48.354C15.1461 51.3584 2.776 43.5569 0.491674 31.7238C-1.49073 21.4561 2.59656 12.1813 11.3978 6.99569C11.8449 6.73095 12.3064 6.44893 12.7991 6.30792C14.0239 5.95397 14.6049 5.26044 14.4768 3.90792C14.4369 3.48202 14.7303 2.83166 15.0778 2.59281C16.3452 1.72087 17.6982 0.978419 19.3302 0.0028777L19.3274 0ZM14.2831 8.12951C13.8929 8.33383 13.6821 8.42303 13.4941 8.54678C5.96039 13.5252 3.92671 22.7799 5.23407 30.2562C6.54998 37.7698 14.4169 45.7296 23.8391 44.777C27.764 44.3799 31.35 43.3612 34.4803 40.9382C36.0354 39.7353 37.3855 38.3252 38.1204 36.4173C37.639 36.5814 37.3086 36.9065 36.927 37.1511C35.2977 38.1986 33.7483 39.4332 31.9994 40.2188C26.6589 42.6188 20.5408 40.8979 17.0317 36.2245C15.0721 33.6173 14.4625 30.6072 14.4426 27.4446C14.4027 21.6518 14.434 15.859 14.4255 10.0662C14.4255 9.47052 14.34 8.87483 14.2831 8.13526V8.12951Z"
      fill="#924c1b"
    />
  </svg>
);
