/** * @module user */

/**
 * Formats a user's full name based on provided firstname and lastname.
 *
 * @param {Object} user - The user object containing firstname and lastname.
 * @param {string} [user.firstname=""] - The firstname of the user.
 * @param {string} [user.lastname=""] - The lastname of the user.
 * @returns {string} The formatted full name of the user.
 *
 * @example
 * const userData = {
 *   firstname: 'John',
 *   lastname: 'Doe'
 * };
 * const fullName = formatFullName(userData);
 * console.log(fullName); // Output: 'John Doe'
 */
export function formatFullName(user) {
  return `${user?.firstname || ""} ${user?.lastname || ""}`.trim();
}

/**
 * Extract user full name from data
 *
 * @param {Object|Object[]|string} data - The data containing user information
 * @param {string} [defaultValue=""] - The default value to return when the data is invalid.
 *
 * @returns {string} The extracted full name(s) or the default value if the data is invalid.
 *
 * @example
 * const userData = [
 *   { firstname: 'John', lastname: 'Doe' },
 *   { firstname: 'Jane', lastname: 'Smith' }
 * ];
 * const fullNames = extractFullName(userData);
 * console.log(fullNames); // Output: 'John Doe, Jane Smith'
 *
 * const singleUserData = { firstname: 'John', lastname: 'Doe' };
 * const fullName = extractFullName(singleUserData);
 * console.log(fullName); // Output: 'John Doe'
 *
 * const emptyData = [];
 * const defaultValue = extractFullName(emptyData, 'No data available');
 * console.log(defaultValue); // Output: 'No data available'
 */
export function extractFullName(data, defaultValue = "") {
  if (!data || data.length === 0) return defaultValue;

  if (Array.isArray(data)) {
    return data.map((user) => formatFullName(user)).join(", ");
  }

  if (typeof data === "object") return formatFullName(data);

  return data;
}

/**
 * Determines if a user can edit their profile.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.userId - The ID of the user whose profile is being edited.
 * @param {string} params.sessionId - The ID of the current session user.
 * @param {Object} params.rights - The rights object containing user permissions.
 * @param {boolean} params.rights["all.admin"] - A flag indicating if the user has admin rights.
 * @returns {boolean} Returns true if the user can edit their profile, otherwise false.
 *
 * @example
 * const params = {
 *   userId: '123',
 *   sessionId: '456',
 *   rights: {
 *     "all.admin": true
 *   }
 * };
 * const canEdit = canEditProfile(params);
 * console.log(canEdit); // Output: true
 */
export function canEditProfile({ userId, sessionId, rights }) {
  return userId === sessionId || !!rights["all.admin"];
}

/**
 * @typedef {Object} Action
 * @property {Object} [consultant] - The consultant object within the action.
 */

/**
 * Returns an array containing either the consultant from the action, the user, or an empty array.
 *
 * @param {Object} params - The parameters object
 * @param {Action} params.action - The action object.
 * @param {Object} params.user - The user object.
 *
 * @return {Object[]} - An array containing either the consultant, the user, or an empty array.
 *
 * @example
 * const params = {
 *   action: {
 *     consultant: { id: '123', name: 'John Doe' }
 *   },
 *   user: { id: '456', name: 'Jane Smith' }
 * };
 * const defaultConsultants = getDefaultConsultantByAction(params);
 * console.log(defaultConsultants);
 * // Output: [{ id: '123', name: 'John Doe' }]
 *
 */
export function getDefaultConsultantByAction({ action, user }) {
  if (action.consultant) return [action.consultant];

  if (user) return [user];

  return [];
}
