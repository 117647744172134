import { notificationApi } from "../notificationApi";

const loadNotifications = notificationApi.injectEndpoints({
  endpoints: (build) => ({
    loadNotifications: build.query({
      query: () => ({
        url: `notification/me`,
        method: "GET",
        params: { unread: 1 },
        data: { unread: 1 },
      }),
      providesTags: (result) =>
        result && result.notification
          ? result.notification.map((e) => ({
              id: e.id,
              type: "Notifications",
            }))
          : ["Notifications"],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyLoadNotificationsQuery } = loadNotifications;
