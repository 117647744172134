export const SENTRY_URL_TAGS_BY_MODULE = {
  KPI: "KPI",
  GROUP: "GROUP",
  MATCHE: "MATCHE",
  COMPANY: "COMPANY",
  DOCUMENT: "DOCUMENT",
  DIRECTORY: "DIRECTORY",
  FOLLOW_UP: "FOLLOW_UP",
  NOT_SPECIFIED: "NOT_SPECIFIED",
  ASSIGNMENT_LIST: "ASSIGNMENT_LIST",
  ASSIGNMENT_CREATION: "ASSIGNMENT_CREATION",
  ASSIGNMENT_MANAGEMENT: "ASSIGNMENT_MANAGEMENT",
  TALENT_DESCRIPTION: "TALENT_DESCRIPTION",
};
