/**
 * @typedef {Object} DueDateOption
 * @property {string} label - The label describing the due date duration.
 * @property {number} value - The numeric value representing the due date in days.
 * @property {number} id - The numeric id representing the id of the due date.
 * @property {string} invoiceDueDays - The frequency of the due date.
 */

/**
 * A list of due date options with labels and values in days.
 *
 * @type {DueDateOption[]}
 */
export const DUE_DATES = [
  {
    id: 0,
    value: 60,
    label: "60 days",
    isEndOfMonth: false,
    invoiceDueDays: "d60",
  },
  {
    id: 1,
    value: 45,
    label: "45 days",
    isEndOfMonth: false,
    invoiceDueDays: "d45",
  },
  {
    id: 2,
    value: 45,
    isEndOfMonth: true,
    label: "45 days end of month",
    invoiceDueDays: "md45",
  },
  {
    id: 3,
    value: 30,
    label: "30 days",
    isEndOfMonth: false,
    invoiceDueDays: "d30",
  },
];

/**
 * Represents the possible states of an invoice.
 *
 * @constant {Object} INVOICE_STATE
 * @property {string} DRAFT - The invoice is in draft mode and has not been finalized.
 * @property {string} BILLING - The invoice is in the billing process.
 * @property {string} VALIDATED - The invoice has been validated and approved.
 */
export const INVOICE_STATE = {
  DRAFT: "draft",
  BILLING: "billing",
  VALIDATED: "validated",
};

/**
 * Represents the different types of invoices.
 *
 * @constant {Object} INVOICE_TYPE
 * @property {string} SOLD - The invoice type for sold services or products.
 * @property {string} ACCOUNT - The invoice type for the first provision (initial payment).
 * @property {string} SHORTLIST - The invoice type for the second provision (subsequent payment).
 */
export const INVOICE_TYPE = {
  SOLD: "Solde",
  ACCOUNT: "Première provision",
  SHORTLIST: "Seconde provision",
};

/**
 * Enum for invoice key names.
 * @readonly
 * @enum {string}
 */
export const INVOICE_KEY_NAME = {
  SOLD: "sold",
  ACCOUNT: "account",
  SHORTLIST: "shortlist",
};

/**
 * An inverted version of INVOICE_TYPE with lowercase keys.
 *
 * @constant {Object} INVOICE_TYPE_INVERTED
 * @property {string} sold - The inverted key for SOLD.
 * @property {string} account - The inverted key for ACCOUNT.
 * @property {string} shortlist - The inverted key for SHORTLIST.
 */
export const INVOICE_TYPE_INVERTED = Object.fromEntries(
  Object.entries(INVOICE_TYPE).map(([key, value]) => [
    value,
    key.toLowerCase(),
  ]),
);

/**
 * Represents the VAT (Value Added Tax) rate as a percentage.
 *
 * @constant {number} VAT_RATE
 * @default 20
 */
export const VAT_RATE = 20;

/**
 * Represents the payment status of an invoice.
 *
 * @constant {Object} INVOICE_PAYMENT_STATUS
 * @property {string} UNPAID - The invoice is unpaid.
 * @property {string} PAID - The invoice is fully paid.
 * @property {string} PART_PAID - The invoice is partially paid.
 */
export const INVOICE_PAYMENT_STATUS = {
  UNPAID: "UNPAID",
  PAID: "PAID",
  PART_PAID: "PART_PAID",
};

/**
 * List of keys used for filtering specific fields.
 * These keys represent properties in the dataset and allow filtering by type, state, number,
 * company details, assignment details, and payment status.
 *
 * @constant {string[]}
 */
export const FILTERED_KEYS_LIST = [
  "type",
  "state",
  "number",
  "company.id",
  "company.name",
  "assignment.id",
  "assignment.reference",
  "is_partially_paid",
];

/**
 * Object defining filter operators for specific fields.
 * Specifies the operator to be used when filtering by certain fields.
 *
 * @constant {Object<string, string>}
 * @property {string} state - The operator used for filtering by state.
 * @property {string} is_partially_paid - The operator used for filtering by payment status.
 */
export const FILTER_OPERATORS = {
  state: "=",
  is_partially_paid: "=",
};

/**
 * Object representing keys with default empty values for filtered fields.
 * Each key corresponds to a specific filterable field, initialized with an empty string.
 *
 * @constant {Object<string, string>}
 * @property {string} type - Default filter value for type.
 * @property {string} state - Default filter value for state.
 * @property {string} number - Default filter value for number.
 * @property {string} company.id - Default filter value for company ID.
 * @property {string} company.name - Default filter value for company name.
 * @property {string} assignment.id - Default filter value for assignment ID.
 * @property {string} assignment.reference - Default filter value for assignment reference.
 * @property {string} is_partially_paid - Default filter value for payment status.
 */
export const KEY_VALUE_FILTERED_KEYS_LIST = {
  type: "",
  state: "",
  number: "",
  "company.id": "",
  "company.name": "",
  "assignment.id": "",
  "assignment.reference": "",
  is_partially_paid: "",
};

/**
 * Repartition value of the producer if the producer and the originator are not in the same team
 *
 * @constant {number} PRODUCER_NOT_IN_THE_SAME_TEAM_REPARTITION_VALUE
 * @default 66.67
 */
export const PRODUCER_NOT_IN_THE_SAME_TEAM_REPARTITION_VALUE = 66.67;

/**
 * Repartition value of the originators if the producer and the originator are not in the same team
 *
 * @constant {number} ORIGINATOR_NOT_IN_THE_SAME_TEAM_REPARTITION_VALUE
 * @default 33.33
 */
export const ORIGINATOR_NOT_IN_THE_SAME_TEAM_REPARTITION_VALUE = 33.33;

/**
 * Repartition value if the producer and the originator are in the same team
 *
 * @constant {number} IN_THE_SAME_TEAM_REPARTITION_VALUE
 * @default 50
 */
export const IN_THE_SAME_TEAM_REPARTITION_VALUE = 50;

/**
 * Repartition value if the producer and the originator are the same.
 *
 * @constant {number} PRODUCER_AND_ORIGINATOR_SAME_REPARTITION_VALUE
 * @default 100
 */
export const PRODUCER_AND_ORIGINATOR_SAME_REPARTITION_VALUE = 100;

/**
 * Default RIB id for executive search
 *
 * @constant {number} EXECUTIVE_SEARCH_DEFAULT_RIB_ID
 * @default 10
 */
export const EXECUTIVE_SEARCH_DEFAULT_RIB_ID = 10;

/**
 * Default issue date multiplier for executive search
 *
 * @constant {number} EXECUTIVE_SEARCH_DEFAULT_ISSUE_DATE_MULTIPLIER
 * @default 1
 */

export const EXECUTIVE_SEARCH_DEFAULT_ISSUE_DATE_MULTIPLIER = 30;
