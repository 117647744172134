import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import {
  ConsultantStatistic,
  FollowUp,
  FollowUpMyTeams,
  StatisticFollowUp,
  TeamStatistic,
} from "routes/pages.import";

export const statisticFollowUp = [
  {
    path: "/follow-up",
    element: (
      <ProtectedRoute layoutType="FOLLOW_UP">
        <StatisticFollowUp />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <FollowUp />,
      },
      {
        path: "",
        element: <FollowUpMyTeams />,
      },
      {
        path: "my-team/consultant/:id",
        element: <ConsultantStatistic />,
      },
      {
        path: "my-team/team/:id",
        element: <TeamStatistic />,
      },
    ],
  },
];
