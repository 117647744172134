import React from "react";
import { Link } from "react-router-dom";

import { AmaLogo } from "../Icons/Logo/AmaLogo";

import styles from "./Logo.module.scss";

const Logo = ({ className, open }) => (
  <div className={`${className || " text-center"}`} data-testid="testid-logo">
    <Link to="/" className={open ? styles.textLogoShow : styles.textLogoHidden}>
      <AmaLogo />
    </Link>
  </div>
);

export default Logo;
