import { Container } from "react-bootstrap";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";

import AppBar from "components/common/AppBar/AppBar";
import { SideBarNav } from "components/common/SideBarNav";
import Suspense from "components/common/Suspense";

import LayoutWrapper from "../LayoutWrapper/LayoutWrapper";

import classes from "./FollowUp.module.scss";

export const FollowUp = ({ children }) => {
  return (
    <LayoutWrapper>
      <Box margin={0} height="100%" width="100%">
        <Container fluid className="p-0 h-100">
          <Box display="flex" height="100%" width="100%">
            <ToastContainer />
            <SideBarNav />
            <Box component="div" className={classes.container}>
              <AppBar className={classes.asideHeader} />
              <Suspense>
                <Container className={classes.asideContent} fluid>
                  {children}
                </Container>
              </Suspense>
            </Box>
          </Box>
        </Container>
        {/* <ChannelBoxContent /> */}
      </Box>
    </LayoutWrapper>
  );
};
