export const DocumentIcon = () => (
  <svg
    width="25"
    height="28"
    viewBox="0 0 25 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="DocumentIcon"
  >
    <path
      d="M15.4995 17.7483L17.3745 19.6233M8.62451 14.6233C8.62451 15.6179 9.0196 16.5717 9.72286 17.2749C10.4261 17.9782 11.3799 18.3733 12.3745 18.3733C13.3691 18.3733 14.3229 17.9782 15.0262 17.2749C15.7294 16.5717 16.1245 15.6179 16.1245 14.6233C16.1245 13.6287 15.7294 12.6749 15.0262 11.9716C14.3229 11.2684 13.3691 10.8733 12.3745 10.8733C11.3799 10.8733 10.4261 11.2684 9.72286 11.9716C9.0196 12.6749 8.62451 13.6287 8.62451 14.6233V14.6233Z"
      stroke="white"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.99951 25.7483V2.24829C2.99951 2.04938 3.07853 1.85861 3.21918 1.71796C3.35983 1.57731 3.5506 1.49829 3.74951 1.49829H18.3145C18.5134 1.49847 18.704 1.5776 18.8445 1.71829L22.7795 5.65329C22.8495 5.72317 22.9049 5.80619 22.9427 5.89757C22.9804 5.98896 22.9998 6.08691 22.9995 6.18579V25.7483C22.9995 25.8468 22.9801 25.9443 22.9424 26.0353C22.9047 26.1263 22.8495 26.209 22.7798 26.2786C22.7102 26.3483 22.6275 26.4035 22.5365 26.4412C22.4455 26.4789 22.348 26.4983 22.2495 26.4983H3.74951C3.65102 26.4983 3.55349 26.4789 3.4625 26.4412C3.3715 26.4035 3.28883 26.3483 3.21918 26.2786C3.14954 26.209 3.09429 26.1263 3.0566 26.0353C3.01891 25.9443 2.99951 25.8468 2.99951 25.7483V25.7483Z"
      stroke="white"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9995 1.49829V5.74829C17.9995 5.9472 18.0785 6.13797 18.2192 6.27862C18.3598 6.41927 18.5506 6.49829 18.7495 6.49829H22.9995"
      stroke="white"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
