import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import { Kpi } from "routes/pages.import";

export const kpi = [
  {
    path: "/kpi",
    element: (
      <ProtectedRoute>
        <Kpi />
      </ProtectedRoute>
    ),
  },
];
