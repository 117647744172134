export const FoldableOpenIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="FoldableOpenIcon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8468 19.8107L16.3918 12.3557L23.8468 4.9007L22.6862 3.7382L14.6518 11.7744L14.6518 12.9351L22.6862 20.9694L23.8468 19.8107ZM2.06118 4.7207L9.69618 12.3557L2.06118 19.9888L3.2218 21.1513L11.4362 12.9332L11.4362 11.7726L3.2218 3.5582L2.06118 4.71882L2.06118 4.7207Z"
      fill="white"
    />
  </svg>
);
