import { LinearProgress } from "@mui/material";
import { Suspense as ReactSuspense } from "react";

export default function Suspense({ children }) {
  return (
    <ReactSuspense fallback={<LinearProgress color="primary" />}>
      {children}
    </ReactSuspense>
  );
}
