import { createSlice } from "@reduxjs/toolkit";

const userInitialState = {
  profileBlobUrl: "",
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState: userInitialState,
  reducers: {
    setProfileBlobUrl: (state, action) => {
      state.profileBlobUrl = action.payload;
      return state;
    },
  },
});

export const { setProfileBlobUrl } = userSlice.actions;
