export const FoldableCloseIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="FoldableCloseIcon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.195 19.8107L3.74002 12.3557L11.195 4.90073L10.0344 3.73823L2.00002 11.7745L2.00002 12.9351L10.0344 20.9695L11.195 19.8107ZM14.6876 4.72073L22.3226 12.3557L14.6876 19.9889L15.8482 21.1514L24.0626 12.9332L24.0626 11.7726L15.8482 3.55823L14.6876 4.71885L14.6876 4.72073Z"
      fill="white"
    />
  </svg>
);
