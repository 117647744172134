export const INTERIM_MANAGEMENT_KANBAN_COLUMNS = [
  {
    id: "identified",
    title: "Identified",
  },
  {
    id: "qualified",
    title: "Qualified",
  },
  {
    id: "lincoln_interview",
    title: "Lincoln Interview",
  },
  {
    id: "short_listed",
    title: "Short Listed",
  },
  {
    id: "client_interview",
    title: "Client Interview",
  },
  {
    id: "in_offer",
    title: "In Offer",
  },
  {
    id: "hired",
    title: "Recruited",
  },
];

export const INTERIM_MANAGEMENT_DRAG_DESTINATION = {
  IDENTIFIED: "identified",
  QUALIFIED: "qualified",
  LINCOLN_INTERVIEW: "lincoln_interview",
  SHORT_LISTED: "short_listed",
  CLIENT_INTERVIEW: "client_interview",
  IN_OFFER: "in_offer",
  HIRED: "hired",
};

export const INTERIM_MANAGEMENT_ACTION_TYPES = {
  LINCOLN_INTERVIEW: "Lincoln Interview",
  PHONE_CALL: "Phone call",
  JOB_OFFER_RECEIVED: "Job offer received",
  SHORT_LISTED: "Short-list sent",
  MESSAGE_EMAIL: "Message/E-mail",
  CANDIDATE_NOT_YET_CONTACTED: "Candidate not yet contacted",
  LONG_LIST: "Long-list",
  CANDIDATE_HIRED: "Candidate hired",
  FOLLOW_UP_ONBOARDING: "Follow up onboarding",
  REFERENCE_CHECK: "Reference check",
  CANDIDATE_REPORT_SEND: "Candidate's report sent",
  SEND_MCPH: "MCPH - Questionnaire sent",
  CLIENT_INTERVIEW: "Client interview",
  MCPH_DEBRIEFING: "MCPH Debriefing",
  SEND_TALE_ME_MORE: "Tale me more sent",
  TALE_ME_MORE_DEBRIEFING: "Tale me more debriefing",
  NO_ACTION: "No action yet",
  PHONE_SKYPE_INTERVIEW: "Phone/skype interview",
  SHORT_LISTED_RESULT: "Short-list",
};

// this object is set depending on the pipe orders
export const ACTION_THAT_MOVES_THE_TALENT = {
  qualified: "Phone call",
  lincoln_interview: "Lincoln Interview | Phone/skype interview",
  short_listed: "Short-list sent",
  client_interview: "Client interview",
  in_offer: "Job offer received",
  hired: "Candidate hired",
};

export const INTERIM_MANAGEMENT_IDS = [13, 16, 27];

/**
 * An object representing the different types of contracts.
 *
 * @constant
 * @type {Object}
 * @property {string} FIXED - Represents a fixed contract type.
 * @property {string} HYBRID - Represents a hybrid contract type.
 * @property {string} SERVICE - Represents a service contract type.
 *
 * @example
 * console.log(CONTRACT_TYPE.FIXED);   // "fixed"
 * console.log(CONTRACT_TYPE.HYBRID);  // "hybrid"
 * console.log(CONTRACT_TYPE.SERVICE); // "service"
 */
export const CONTRACT_TYPE = {
  FIXED: "fixed",
  HYBRID: "hybrid",
  SERVICE: "service",
};
