import { useMemo, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";

import { containsUrl } from "utils/helpers/link";

import styles from "../SideBarNav.module.scss";

const NavItemLink = ({ item }) => {
  const location = useLocation();
  const { icon, text, link, isExternalLink, id } = item;

  const isActive = useMemo(() => {
    if (link === "/") return link === location.pathname;
    return containsUrl(link, location.pathname);
  }, [location, link]);

  const target = isExternalLink ? "_blank" : "_self";

  const handleClick = useCallback(
    (e) => {
      if (isExternalLink) {
        e.preventDefault();
        return window.open(link, target);
      }
    },
    [isExternalLink]
  );

  return (
    <NavLink
      key={id}
      to={!isExternalLink && link}
      className={isActive ? styles.sideItemActive : styles.sideitem}
      onClick={handleClick}
    >
      <Box display="flex" gap={2}>
        {icon}
        <Box component="span" className={styles.linkText}>
          {text}
        </Box>
      </Box>
    </NavLink>
  );
};

export default NavItemLink;
