/** * @module config */

import { API_BASE, STAGES } from "utils/constants/environment/config";

import ExtjsStateManager from "../extjs-state-manager";

/**
 * Retrieves the default API base label from ExtJS StateManager or sets it if not already set.
 *
 * This function searches for the default API base label "Europe" in API_BASE array.
 * If ExtJS StateManager does not have an "ext-api-base" stored and a default base is found,
 * it sets "ext-api-base" to the default base label.
 *
 * @returns {string|null} The API base label retrieved from ExtJS StateManager, or null if not found.
 */
export function getDefaultApiBase() {
  const defaultBase = API_BASE.find((api) => api?.label === "Europe");
  const apiBase = ExtjsStateManager.get("ext-api-base");

  if (!apiBase && defaultBase) {
    ExtjsStateManager.set("ext-api-base", defaultBase.label);
  }

  return apiBase;
}

/**
 * Retrieves the API configuration based on the provided API base label or defaults to the default API base.
 *
 * @param {string} [apiBase=""] - Optional. The API base label to retrieve configuration for. Defaults to the default API base label retrieved from getDefaultApiBase().
 * @returns {Object|null} The API configuration object corresponding to the API base label, or null if not found.
 */
function getApi(apiBase = "") {
  const defaultApiBase = apiBase || getDefaultApiBase();
  const api = API_BASE.find((api) => api?.label === defaultApiBase);
  return api;
}

/**
 * Retrieves the media URL from the API configuration based on the provided API base label or defaults to the default API base.
 *
 * @param {string} [apiBase=""] - Optional. The API base label to retrieve media URL for. Defaults to the default API base label.
 * @returns {string} The media URL corresponding to the API base label, or an empty string if not found.
 */
export function getMediaUrl(apiBase = "") {
  const api = getApi(apiBase);
  return api?.media_url || "";
}

/**
 * Determines the current stage based on the hostname of the window location.
 *
 * @returns {string} The current stage based on the hostname:
 * - "DEV" for ama-dev environment
 * - "PRE_PROD" for ama-preprod environment
 * - "LOCAL" for local environment
 * - "PROD" for production environment (default)
 */
export function getStage() {
  const location = window.location.hostname;

  if (location.includes("ama-dev")) return STAGES.DEV;
  if (location.includes("ama-preprod")) return STAGES.PRE_PROD;
  if (location.includes("local")) return STAGES.LOCAL;
  return STAGES.PROD;
}

/**
 * Retrieves the API URL from the API configuration based on the provided API base label or defaults to the default API base.
 *
 *  * - Use this function inside utility functions.
 * - If you need to call this inside a component or custom hooks,
 *   please import and use `getApiUrl` from `store/session-context.js`.
 *
 * @param {string} [apiBase=""] - Optional. The API base label to retrieve URL for. Defaults to the default API base label.
 * @returns {string} The API URL corresponding to the API base label, or an empty string if not found.
 */
export function getApiUrl(apiBase = "") {
  const api = getApi(apiBase);
  return api?.url || "";
}
