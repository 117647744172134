import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import { Notifications } from "routes/pages.import";

export const notification = [
  {
    path: "/notifications",
    element: (
      <ProtectedRoute>
        <Notifications />
      </ProtectedRoute>
    ),
  },
  {
    path: "/notifications/:id",
    element: (
      <ProtectedRoute>
        <Notifications />
      </ProtectedRoute>
    ),
  },
];
