export const KPIIcon = () => (
  <svg
    width="28"
    height="25"
    viewBox="0 0 28 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="KPIIcon"
  >
    <path
      d="M1.57 22.3935L9.18 14.706L13.2925 18.6435L18.3187 14.2498L21.1187 16.9798M26.5 4.87476C26.5 4.37747 26.3025 3.90056 25.9508 3.54893C25.5992 3.1973 25.1223 2.99976 24.625 2.99976H3.375C2.87772 2.99976 2.40081 3.1973 2.04917 3.54893C1.69754 3.90056 1.5 4.37747 1.5 4.87476V10.4998H26.5V4.87476Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5 9.24976V21.1248C26.5 21.622 26.3025 22.0989 25.9508 22.4506C25.5992 22.8022 25.1223 22.9998 24.625 22.9998H6.5M10.945 6.74976H22.195M5.94375 6.74976H7.19375M1.5 9.24976V14.8748"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
