import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import { Directory } from "routes/pages.import";

export const directory = [
  {
    path: "/directory",
    element: (
      <ProtectedRoute>
        <Directory />
      </ProtectedRoute>
    ),
  },
];
