import axios from "axios";

import { sendFeedbackToSentry } from "utils/helpers/sentry";
import ExtjsStateManager from "utils/extjs-state-manager";
import { showToastOnError } from "utils/helpers/assignment";
import { getApiUrl } from "utils/helpers/configuration";

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params, queryType, headers }) => {
    const apiBaseUrl = baseUrl || getApiUrl();

    if (queryType === "LoginQuery") {
      try {
        const result = await axios({
          url: apiBaseUrl + url,
          method: "POST",
          data,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      const session = ExtjsStateManager.get("ext-session");

      try {
        const token = session?.tokenApi.token;
        const tokenType = { token };
        const currentUrl = apiBaseUrl + url;
        const contentType = {};

        const result = await axios({
          url: currentUrl,
          method,
          data,
          params,
          headers: {
            Accept: "application/json",
            ...contentType,
            ...tokenType,
            ...(headers ?? {}),
          },
        });

        if (
          result.data &&
          result.data["success"] !== undefined &&
          !result.data.success
        ) {
          throw new Error();
        }
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError;

        // Send feedback error to sentry
        sendFeedbackToSentry({
          data,
          session,
          error: err,
        });

        if (err.code && err.code === "ERR_NETWORK") {
          showToastOnError("Network Error");
        } else if (err.response && err.response?.status === 400) {
          showToastOnError(
            err.response?.data?.message ??
              "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
          );
        } else if (err.response && err.response?.status === 422) {
          showToastOnError(
            err.response?.data?.message ??
              "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
          );
        } else if (err.response && err.response?.status === 404) {
          showToastOnError(
            "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
          );
        } else if (err.response && err.response?.status === 500) {
          showToastOnError(
            "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
          );
        } else if (
          err?.code === "ERR_BAD_REQUEST" &&
          err?.response?.status === 401 &&
          err?.response?.data?.message === "Bad token"
        ) {
          return;
        } else {
          showToastOnError(
            err.response?.data?.message ??
              "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
          );
        }

        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };
