export const NEGATIVE_ANSWER_RESULT_IDS = [24, 25, 106];

export const POSITIVE_RESULT_ACTION_OPTIONS = ["Shortlisted"];

export const SHORT_LISTED = {
  id: 117,
  name: "Shortlisted",
};

export const JOB_OFFER_RESULT_TYPES = {
  JOB_OFFER_ACCEPTED: "Job offer accepted",
  JOB_OFFER_IN_NEGOTIATION: "Job offer- In Negotiation",
};

export const NEGATIVE_ANSWER_RESULT_NAMES = {
  LINCOLN_DECLINED: "Lincoln declined",
  CLIENT_DECLINED: "Client declined",
};

/**
 * An Array of declined result name.
 *
 * @type {Array<string>}
 * @default - ["Lincoln declined", "Client declined"]
 * @memberof assignment
 */
export const RESULT_DECLINED_NAME = ["Lincoln declined", "Client declined"];

/**
 * An array of result codes representing declined outcomes.
 *
 * @type {Array<number>}
 * @default - [24, 25, 106]
 * @memberof assignment
 */
export const RESULT_DECLINED = [24, 25, 106];

export const NEGATIVE_WORDS = [
  "Refus",
  "rejetée",
  "declined",
  "Negative",
  "Not open to",
  "Does not wish",
];
