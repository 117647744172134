export const AssignmentIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="AssignmentIcon"
  >
    <path
      d="M15.4995 6.49951V3.99951H10.4995V6.49951H15.4995ZM2.99951 10.2495V21.4995C2.99951 22.187 3.56201 22.7495 4.24951 22.7495H21.7495C22.437 22.7495 22.9995 22.187 22.9995 21.4995V10.2495C22.9995 9.56201 22.437 8.99951 21.7495 8.99951H4.24951C3.56201 8.99951 2.99951 9.56201 2.99951 10.2495ZM22.9995 6.49951C24.387 6.49951 25.4995 7.61201 25.4995 8.99951V22.7495C25.4995 24.137 24.387 25.2495 22.9995 25.2495H2.99951C1.61201 25.2495 0.499512 24.137 0.499512 22.7495L0.512012 8.99951C0.512012 7.61201 1.61201 6.49951 2.99951 6.49951H7.99951V3.99951C7.99951 2.61201 9.11201 1.49951 10.4995 1.49951H15.4995C16.887 1.49951 17.9995 2.61201 17.9995 3.99951V6.49951H22.9995Z"
      fill="white"
    />
  </svg>
);
