import React, { useContext, useEffect, useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import { RiMessengerFill } from "react-icons/ri";

import styles from "./Chat.module.scss";
import { openMessageBox } from "../../store/redux/slice/layout/layoutSlice";
import SessionContext from "../../store/session-context";

const CountNotRead = () => {
  const sessionCtx = useContext(SessionContext);
  const dispatch = useDispatch();
  const [nbMessages, setNbMessages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getNbMessages = () => {
      if (!isLoading) {
        setIsLoading(true);
        const params = {
          requester_channel_user_type: "agent",
          requester_channel_user_token: sessionCtx?.user?.id,
        };

        const config = {
          method: "GET",
          url: `${sessionCtx?.connect?.url}/api/chat/channel-users/unread-messages-nb`,
          params,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${sessionCtx?.connect?.api_chat_key}`,
          },
        };

        axios(config)
          .then((response) => {
            setNbMessages(response.data.unread_messages_nb);
          })
          .catch((error) => {
            if (error?.message && error?.response?.status === 500) {
              return;
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };
    getNbMessages();
    // const interval = setInterval(() => {
    //   getNbMessages();
    // }, 5000);
    // return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [
    sessionCtx?.connect?.api_chat_key,
    sessionCtx?.connect?.url,
    sessionCtx?.user?.id,
  ]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Chat
    </Tooltip>
  );

  return (
    <span
      href="#"
      type="button"
      style={{ width: "fit-content", position: "relative" }}
      onClick={() => {
        dispatch(openMessageBox());
      }}
    >
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <div className="d-flex">
          <RiMessengerFill className={styles.navItem} />
        </div>
      </OverlayTrigger>
      {nbMessages > 0 && (
        <Badge
          pill
          className=" position-absolute start-100 translate-middle"
          style={{ background: "rgb(103, 58, 183)", top: 4, fontSize: "12px" }}
        >
          {nbMessages}
        </Badge>
      )}
    </span>
  );
};

export default CountNotRead;
