/* eslint-disable no-unused-vars */
/** * @module talent */

import dayjs from "dayjs";

import { NEGATIVE_ANSWER_RESULT_NAMES } from "utils/constants/result/result";
import { TALENT_CONTACT_TYPES } from "utils/constants/talent/contact-types";

import { extractCombinedName, getValueOrDefault } from "./string";
import { createContentItem } from "./object";

/**
 * Constructs a payload data object for talent information based on provided values.
 *
 * @param {Object} values - The object containing values for constructing the payload.
 * @param {string} values.lastName - The last name of the talent.
 * @param {string} values.firstName - The first name of the talent.
 * @param {string} values.benefits - The benefits information of the talent.
 * @param {number} values.interestAmount - The interest amount of the talent.
 * @param {string} values.interestPercentage - The interest percentage of the talent.
 * @param {Array<Object>} values.interestMode - The interest mode options of the talent.
 * @param {boolean} values.isAdrPurchase - Indicates if ADR purchase is active for the talent.
 * @param {number} values.adrPurchase - The ADR purchase value of the talent.
 * @param {number} values.lti - The long term incentive value of the talent.
 * @param {number} values.fixPret - The fixed pretension value of the talent.
 * @param {number} values.fixRem - The fixed remuneration value of the talent.
 * @param {number} values.varPret - The variable pretension value of the talent.
 * @param {number} values.varRem - The variable remuneration value of the talent.
 * @param {Object} values.salaryCurrency - The salary currency object of the talent.
 * @param {Array<Object>} values.status - The status array of the talent.
 * @returns {Object} The payload data object constructed for talent information.
 *
 * @example
 * const payloadValues = {
 *   lastName: 'Doe',
 *   firstName: 'John',
 *   benefits: 'Health insurance, Retirement plan',
 *   interestAmount: 5000,
 *   interestPercentage: '3%',
 *   interestMode: [{ id: 'fix' }],
 *   isAdrPurchase: true,
 *   adrPurchase: 2000,
 *   lti: 10000,
 *   fixPret: 60000,
 *   fixRem: 55000,
 *   varPret: 10000,
 *   varRem: 8000,
 *   salaryCurrency: [{ id: 'USD' }],
 *   status: [{ id: 1 }, { id: 2 }]
 * };
 * const payloadData = getTalentPayloadData(payloadValues);
 * console.log(payloadData);
 * // Output:
 * // {
 * //   firstname: 'John',
 * //   lastname: 'Doe',
 * //   salary_fixed: 55000,
 * //   salary_variable: 8000,
 * //   salary_wanted_fixed: 60000,
 * //   salary_wanted_variable: 10000,
 * //   salary_benefit: 'Health insurance, Retirement plan',
 * //   currency: 'USD',
 * //   long_term_incentive: 10000,
 * //   average_daily_rate: 2000,
 * //   interest_mode: 'fix',
 * //   interest_amount: 5000,
 * //   contact_types: { '1': 1, '2': 1 }
 * // }
 */
export function getTalentPayloadData(values) {
  const {
    lastName,
    firstName,
    benefits,
    interestAmount,
    interestPercentage,
    interestMode,
    isAdrPurchase,
    adrPurchase,
    lti,
    fixPret,
    fixRem,
    varPret,
    varRem,
    salaryCurrency,
    status,
  } = values;

  const trimPercentage = (value) => value.replace("%", "");

  const contactTypes = TALENT_CONTACT_TYPES.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]: status.find((el) => el.id === curr.id) ? 1 : 0,
    };
  }, {});

  const data = {
    firstname: firstName,
    lastname: lastName,
    salary_fixed: Number(fixRem) || 0,
    salary_variable: Number(varRem) || 0,
    salary_wanted_fixed: Number(fixPret) || 0,
    salary_wanted_variable: Number(varPret) || 0,
    salary_benefit: benefits || "",
    currency: salaryCurrency?.[0]?.id || "",
    long_term_incentive: Number(lti) || 0,
    average_daily_rate: isAdrPurchase ? Number(adrPurchase || 0) : 0,
    interest_mode: interestMode?.length === 0 ? "fix" : interestMode?.[0].id,
    interest_amount:
      interestMode?.length === 0
        ? 0
        : interestMode?.[0].id === "fix"
        ? Number(interestAmount)
        : Number(trimPercentage(interestPercentage || "")),
    contact_types: {
      ...contactTypes,
    },
  };

  return data;
}

/**
 * Retrieves default status objects from a provided object based on truthy values.
 *
 * @param {Object} arg - The object containing status information.
 * @returns {Array<Object>} An array of status objects that are truthy in the provided object.
 *
 * @example
 * const statusObj = {
 *   '1': true,
 *   '2': false,
 *   '3': true
 * };
 * const defaultStatus = getDefaultStatusFromObj(statusObj);
 * console.log(defaultStatus);
 * // Output: [
 * //   { id: '1', name: 'Status 1' },
 * //   { id: '3', name: 'Status 3' }
 * // ]
 */
export function getDefaultStatusFromObj(arg) {
  if (!arg) return [];

  const activeStatus = Object.entries(arg).reduce((acc, curr) => {
    if (curr[1]) return [...acc, curr[0]];
    return acc;
  }, []);

  const status = TALENT_CONTACT_TYPES.filter((item) =>
    activeStatus.includes(item?.id),
  );

  return status;
}

/**
 * Determines if a talent should display a negative answer based on their actions.
 *
 * @param {Object} talent - The talent object containing actions and status information.
 * @param {Array<Object>} talent.actions - The actions performed by the talent.
 * @param {boolean} talent.rejected - Indicates if the talent has been rejected.
 * @param {boolean} talent.negative_answer - Indicates if the talent has a negative answer.
 * @returns {boolean} `true` if the talent should display a negative answer, otherwise `false`.
 *
 * @example
 * const talent = {
 *   actions: [
 *     { result_type: 'NEGATIVE_RESULT' },
 *     { result_type: 'POSITIVE_RESULT' }
 *   ],
 *   rejected: true,
 *   negative_answer: false
 * };
 * const shouldDisplayNegativeAnswer = showTalentNegativeAnswer(talent);
 * console.log(shouldDisplayNegativeAnswer); // Output: true
 */
export function showTalentNegativeAnswer(talent) {
  if (!talent?.actions?.length || !talent.rejected || talent.negative_answer) {
    return false;
  }

  return talent.actions.some((action) =>
    Object.values(NEGATIVE_ANSWER_RESULT_NAMES).includes(action?.result_type),
  );
}

/**
 * Extracts salary benefit, mobility, and locations information from a talent object.
 *
 * @param {Object} talent - The talent object containing salary, mobility, and location information.
 * @param {string} talent.salary_benefit - The salary benefits of the talent.
 * @param {Array<string>} talent.mobility - The mobility options of the talent.
 * @param {Array<string>} talent.location - The locations of the talent.
 * @returns {Object} An object containing extracted salary benefit, mobility, and locations.
 *
 * @example
 * const talentData = {
 *   salary_benefit: 'Health insurance, Retirement plan',
 *   mobility: ['Remote', 'On-site'],
 *   location: ['New York', 'San Francisco']
 * };
 * const extractedInfo = talentAdvantageAndMobility(talentData);
 * console.log(extractedInfo);
 * // Output:
 * // {
 * //   salaryBenefit: 'Health insurance, Retirement plan',
 * //   mobility: ['Remote', 'On-site'],
 * //   locations: ['New York', 'San Francisco']
 * // }
 */
export function talentAdvantageAndMobility(talent) {
  return {
    salaryBenefit: talent.salary_benefit ?? "",
    mobility:
      talent.mobility?.map((item) => ({
        ...item,
        description: item.country ?? "",
      })) ?? [],
    locations: talent.location ?? [],
  };
}

/**
 * Extracts and formats availability details from the provided talent.
 *
 * @param {Object} talent - The talent containing availability information.
 * @param {string} [talent.availability_date] - The availability date of the talent.
 * @returns {Object} An object containing formatted availability data.
 * @returns {boolean} return.availability - Indicates if the availability date is provided.
 * @returns {boolean} return.isAvailabilityDisabled - Indicates if the availability date is provided (same as `availability`).
 * @returns {Object|null} return.availabilityDate - The availability date as a dayjs object, or null if not provided.
 *
 * @example
 * const talentData = {
 *   availability_date: '2024-07-05',
 * };
 * const formattedAvailability = formatTalentAvailability(talentData);
 * console.log(formattedAvailability);
 * // Output:
 * // {
 * //   availability: true,
 * //   isAvailabilityDisabled: true,
 * //   availabilityDate: dayjs('2024-07-05')
 * // }
 */
export function formatTalentAvailability(talent) {
  return {
    availability: !!talent.availability_date,
    isAvailabilityDisabled: !!talent.availability_date,
    availabilityDate: talent.availability_date
      ? dayjs(talent.availability_date)
      : null,
  };
}

/**
 * Extracts and formats the result type from the provided action.
 *
 * @param {Object} action - The action object containing result information.
 * @param {string} [action.result_type] - The result type of the action.
 * @returns {Object[]} An array containing the result type object if provided, otherwise an empty array.
 * @returns {Object} return[].name - The name of the result type.
 *
 * @example
 * const actionData = {
 *   result_type: 'Interview Scheduled',
 * };
 * const extractedResult = talentResult(actionData);
 * console.log(extractedResult);
 * // Output: [{ name: 'Interview Scheduled' }]
 */
export function talentResult(action) {
  return action.result_type ? [{ name: action.result_type }] : [];
}

/**
 *
 * @param {number} state - Number defining status returned by the api
 * @returns {"Candidate"|"Client"|"Prospect"|"Provider"|"All"|""} Talent Status
 */
export function getContactStatus(state) {
  const statusMap = {
    0: "Candidate",
    1: "Client",
    2: "Prospect",
    3: "Provider",
    4: "All",
  };

  return statusMap[state] || "";
}

export const DEFAULT_STATUS_TALENT_MAP = [
  "candidate",
  "client",
  "prospect",
  "provider",
];

export function getContactTypes(contactTypes) {
  if (!contactTypes) return [];
  const states = Object.keys(contactTypes).filter(
    (key) => contactTypes[key] && DEFAULT_STATUS_TALENT_MAP.includes(key),
  );

  return states || [];
}

/**
 * Formats and sorts a list of talent objects by their name.
 *
 * This function takes an array of talent objects and returns a new array
 * where each object includes a formatted `name` property. The `name` is
 * composed of the talent's `firstname`, `lastname`, and `company`, separated
 * by spaces and a comma. The returned array is sorted alphabetically by
 * the `name` property.
 *
 * @typedef TalentOriginatorType -The originator talent type.
 * @property {string} firstname - The first name of the talent.
 * @property {string} lastname - The last name of the talent.
 * @property {string} company - The company of the talent.
 * @property {string} name - The full name of the talent.
 *
 * @param {Array<TalentOriginatorType>} talents - An array of talent objects.
 *
 * @returns {Array<TalentOriginatorType>} A new array of talent objects, each with a formatted `name` property, sorted alphabetically by name.
 */
export function formatTalentOriginator(talents) {
  return talents
    .map((talent) => {
      return {
        ...talent,
        name: `${talent.firstname ? `${talent.firstname}` : ""} ${
          talent.lastname ? `${talent.lastname}` : ""
        } ${talent.company ? `, ${talent.company}` : ""}`,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}

/**
 * @typedef {Object} ContentItem
 * @property {string} title - The title of the content item.
 * @property {string} key - The key used to identify the content item.
 * @property {string|number} value - The value associated with the content item.
 */

/**
 * @typedef {Object} TalentData
 * @property {Array<Object>} [educations] - An array of education data for the talent.
 * @property {string} [seniority] - The seniority level of the talent.
 * @property {Array<Object>} [softSkills] - An array of soft skills associated with the talent.
 * @property {Array<Object>} [hardSkills] - An array of hard skills associated with the talent.
 * @property {Array<Object>} [languages] - An array of languages spoken by the talent.
 * @property {Array<string>} [keywords] - A list of keywords associated with the talent.
 * @property {Array<Object>} [clientsTarget] - A list of target clients.
 * @property {Array<Object>} [clientsLimited] - A list of limited clients.
 */

/**
 * Formats the talent description content for the talent data by generating an array of content items.
 *
 * @param {TalentData} data - The talent data containing educations, seniority, skills, and other details.
 * @returns {ContentItem[]} - An array of content item objects, each containing title, key, and value fields.
 */
export function formatTalentTalentDescriptionContentDetails(data) {
  return [
    // createContentItem(
    //   "Education",
    //   "educations",
    //   getValueOrDefault(extractCombinedName(data?.educations)),
    // ),
    createContentItem(
      "Seniority",
      "seniority",
      getValueOrDefault(data?.seniority),
    ),
    // createContentItem(
    //   "Soft skill(s)",
    //   "soft-skills",
    //   getValueOrDefault(extractCombinedName(data?.softSkills)),
    // ),
    // createContentItem(
    //   "Hard skill(s)",
    //   "hard-skills",
    //   getValueOrDefault(extractCombinedName(data?.hardSkills)),
    // ),
    // createContentItem(
    //   "Language(s)",
    //   "languages",
    //   getValueOrDefault(extractCombinedName(data?.languages)),
    // ),
    createContentItem(
      "Keyword(s)",
      "keywords",
      getValueOrDefault(data?.keywords?.join(", ")),
    ),
    // createContentItem(
    //   "Target client",
    //   "target-client",
    //   getValueOrDefault(extractCombinedName(data?.clientsTarget)),
    // ),
    // createContentItem(
    //   "Limited client",
    //   "limited-client",
    //   getValueOrDefault(extractCombinedName(data?.clientsLimited)),
    // ),
  ];
}
