export const HomeIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="HomeIcon"
  >
    <path
      d="M1.24973 13.7503H2.49973V22.5002C2.49973 23.879 3.62098 25.0002 4.99973 25.0002H19.9997C21.3785 25.0002 22.4997 23.879 22.4997 22.5002V13.7503H23.7497C23.9969 13.7502 24.2385 13.6769 24.4441 13.5395C24.6496 13.4021 24.8097 13.2069 24.9043 12.9786C24.9989 12.7502 25.0237 12.4989 24.9755 12.2565C24.9273 12.014 24.8082 11.7913 24.6335 11.6165L13.3835 0.366501C13.2675 0.250319 13.1297 0.158147 12.9781 0.0952585C12.8265 0.0323701 12.6639 0 12.4997 0C12.3356 0 12.173 0.0323701 12.0214 0.0952585C11.8697 0.158147 11.732 0.250319 11.616 0.366501L0.365984 11.6165C0.191222 11.7913 0.0722138 12.014 0.0240044 12.2565C-0.0242051 12.4989 0.000548689 12.7502 0.0951362 12.9786C0.189724 13.2069 0.349898 13.4021 0.55541 13.5395C0.760923 13.6769 1.00255 13.7502 1.24973 13.7503ZM12.4997 3.01775L19.9997 10.5178V16.2503L20.001 22.5002H4.99973V10.5178L12.4997 3.01775Z"
      fill="white"
    />
    <path
      d="M12.4998 20.0003C17.1285 20.0003 18.626 15.5765 18.6873 15.389L16.3123 14.6128C16.3023 14.6415 15.336 17.5003 12.4998 17.5003C9.70226 17.5003 8.72476 14.724 8.68601 14.6053L6.31226 15.389C6.37351 15.5765 7.87101 20.0003 12.4998 20.0003Z"
      fill="white"
    />
  </svg>
);
