import { Col, Container, Navbar, Row } from "react-bootstrap";
import { IoHelpBuoySharp } from "react-icons/io5";
import { MdSlowMotionVideo } from "react-icons/md";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

import Zest from "assets/media/zest.png";

// Logo validation for TTA is in progress
// import TTA from "assets/media/tta.png";

import CountNotRead from "../../Chat/CountNotRead";
import NotificationsMenu from "../../Notifications/NotificationsMenu";
import TopProfile from "../../Profile/TopProfile";
import NavItem from "../NavBar/Item";

import headerStyles from "./AppBar.module.scss";

const AppBar = ({ className }) => (
  <header className={`${className}`}>
    <Container
      className="d-flex justify-content-center p-0 mw-100 bg-info"
      data-testid="app-bar-id"
    >
      <Navbar
        expand="lg"
        style={{
          background: "rgba(0, 133, 127, 0.2)",
          // background: "#ffbaba",
          height: 74,
          paddingLeft: 40,
          paddingRight: 40,
        }}
        className="w-100 py-0"
      >
        <Row className={`${headerStyles.navBarHeaderContainer} h-100 w-100`}>
          <Col
            xs={3}
            md={6}
            lg={6}
            className={`${headerStyles.navBarCol} h-100 p-0`}
          >
            <Row className="h-100 d-flex justify-content-end align-items-center p-0">
              <TopProfile />
            </Row>
          </Col>
          <Col
            className={`${headerStyles.navBarCol} h-100 p-0`}
            xs={3}
            md={6}
            lg={6}
          >
            <Row className="h-100 p-0">
              <Col
                xs={4}
                md={4}
                lg={4}
                className={`${headerStyles.headerPhoneBook} h-100 p-0`}
              >
                <CountNotRead />
                <NotificationsMenu />

                <span className="p-0 mx-2" style={{ color: "#00857F" }}>
                  |
                </span>

                <NavItem
                  className={headerStyles.navItemRefacto}
                  link="https://web.microsoftstream.com/?referrer=https://link-on.lincoln-group.com/"
                  name="Microsoft Stream"
                  isExternalLink
                  target="_blank"
                >
                  <MdSlowMotionVideo className={headerStyles.navItemMD} />
                </NavItem>

                <NavItem
                  link="https://inesys.atlassian.net/servicedesk/customer/portal/1"
                  className={headerStyles.navItemRefacto}
                  name="Helpdesk"
                  isExternalLink
                  target="_blank"
                >
                  <IoHelpBuoySharp className={headerStyles.navItemMD} />
                </NavItem>

                <NavItem
                  className={headerStyles.navItemRefacto}
                  link="https://lincoln-hrgroup.ilucca.net/home"
                  name="SIRH"
                  isExternalLink
                  target="_blank"
                >
                  <svg
                    className={headerStyles.navItemSm}
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9295 11.0681C13.9056 10.0446 12.5172 9.46967 11.0695 9.46967C9.62183 9.46967 8.2334 10.0446 7.20952 11.0681L3.34827 14.9281C2.32437 15.952 1.74915 17.3407 1.74915 18.7887C1.74915 20.2367 2.32437 21.6254 3.34827 22.6493C4.37217 23.6732 5.76088 24.2484 7.20889 24.2484C8.65691 24.2484 10.0456 23.6732 11.0695 22.6493L12.9995 20.7193"
                      stroke="currentColor"
                      strokeWidth="2.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.0695 14.9281C12.0934 15.9515 13.4818 16.5264 14.9295 16.5264C16.3772 16.5264 17.7656 15.9515 18.7895 14.9281L22.6508 11.0681C23.6747 10.0442 24.2499 8.65544 24.2499 7.20743C24.2499 5.75941 23.6747 4.3707 22.6508 3.3468C21.6269 2.3229 20.2382 1.74768 18.7901 1.74768C17.3421 1.74768 15.9534 2.3229 14.9295 3.3468L12.9995 5.2768"
                      stroke="currentColor"
                      strokeWidth="2.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </NavItem>

                <NavItem
                  className={headerStyles.navItemRefacto}
                  link="https://zestmeup.com/app/#/r/user/home  "
                  name="Zest"
                  isExternalLink
                  target="_blank"
                >
                  <img width={75} src={Zest} alt="zest" />
                </NavItem>
                <NavItem
                  className={headerStyles.navItemRefacto}
                  link="https://lincoln-thetalentclubacademy.talentlms.com/index"
                  name="The Talent Agent Academy"
                  isExternalLink
                  target="_blank"
                >
                  {/* <img width={40} height={40} src={TTA} alt="tta" /> */}
                  <SchoolOutlinedIcon className={headerStyles.navItemMD} />
                </NavItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </Navbar>
    </Container>
  </header>
);

export default AppBar;
