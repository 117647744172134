import { Box, Container } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { styled } from "@mui/material/styles";

import AppBar from "components/common/AppBar/AppBar";
import { SideBarNav } from "components/common/SideBarNav";

import LayoutWrapper from "../LayoutWrapper/LayoutWrapper";

const ContainerBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  padding: 0,
  width: "calc(100% - 283px)",
  transition: "all 0.8s ease-in-out",
}));

export default function AssignmentTimeline({ children }) {
  return (
    <LayoutWrapper>
      <Box margin={0} height="100%" width="100%">
        <Box display="flex" height="100%" width="100%">
          <ToastContainer />
          <SideBarNav />
          <ContainerBox component="div">
            <AppBar />
            <Container maxWidth="false">{children}</Container>
          </ContainerBox>
        </Box>
      </Box>
    </LayoutWrapper>
  );
}
