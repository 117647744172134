import React, { useEffect, useState, useCallback } from "react";
import { Bell } from "react-bootstrap-icons";

import styles from "./Notification.module.scss";
import NavItem from "../common/NavBar/Item";
import { useLazyLoadNotificationsQuery } from "store/redux/api/notification/loadNotifications";

const NotificationsMenu = () => {
  const [allData, setAllData] = useState();
  const [loadNotifications] = useLazyLoadNotificationsQuery();

  const handleNotification = useCallback(() => {
    loadNotifications().then((resp) => {
      if (resp && resp.data && resp.data.notification) {
        setAllData(resp.data);
      }
    });
  }, [loadNotifications]);

  useEffect(() => {
    handleNotification();
    // const interval = setInterval(handleNotification, 5000);
    // return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="d-flex h-auto"
      style={{ width: "fit-content", lineHeight: "initial" }}
    >
      <NavItem
        name="Notifications"
        className="p-0 m-0 position-relative"
        link="/notifications"
        target="_self"
      >
        <Bell className={styles.navItem} />
        <div
          style={{
            position: "absolute",
            borderRadius: 5,
            color: "white",
            fontSize: 7,
            border: "none",
            justifyContent: "center",
            ...(allData && allData.meta && allData.meta.total > 0
              ? {
                  top: 2,
                  backgroundColor: "#E50046",
                  border: "none",
                  padding: "0px 2px 2px 1px",
                  textAlign: "center",
                  height: 9,
                  right: `${allData.meta.total}`.length > 1 ? "1px" : "4px",
                  width: `${allData.meta.total}`.length > 1 ? "10px" : "8px",
                }
              : {
                  top: 2,
                  backgroundColor: "transparent",
                  border: "none",
                  height: 8,
                  width: 8,
                  right: 3,
                }),
          }}
        >
          {allData && allData.meta && allData.meta.total > 0
            ? allData.meta.total
            : ""}
        </div>
      </NavItem>
    </div>
  );
};

export default NotificationsMenu;
