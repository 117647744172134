import React, { useContext } from "react";
import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import * as Icon from "react-bootstrap-icons";
import { FaSignOutAlt } from "react-icons/fa";

import SessionContext from "../../store/session-context";
import TopProfileImage from "./TopProfileImage";
import { api } from "store/redux/api/api";
import { useDispatch } from "react-redux";

const TopProfile = () => {
  const dispatch = useDispatch();

  const sessionCtx = useContext(SessionContext);

  const logoutHandler = () => {
    sessionCtx.logout();
    dispatch(api.util.resetApiState());
  };

  return (
    <NavDropdown
      title={<TopProfileImage />}
      menuVariant="light"
      align="end"
      style={{ width: "fit-content", padding: 0 }}
    >
      <NavDropdown.Item
        as={Link}
        to="/user-profile"
        className="d-flex align-items-center gap-2 py-2"
      >
        <Icon.Person />
        <span style={{ lineHeight: "1rem" }}>Profile</span>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={logoutHandler}
        className="d-flex align-items-center gap-2 py-2"
      >
        <FaSignOutAlt />
        <span style={{ lineHeight: "1rem" }}>Logout</span>
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default TopProfile;
