import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import { Matche } from "routes/pages.import";

export const matche = [
  {
    path: "/matche/:id",
    element: (
      <ProtectedRoute>
        <Matche />
      </ProtectedRoute>
    ),
  },
];
