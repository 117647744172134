const DirectoryIcon = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="DirectoryIcon"
  >
    <path
      d="M26.1 15.885H18.4V15.4405C18.4 14.0902 17.2962 12.9961 15.95 12.9961H13.05C11.7038 12.9961 10.6 14.0902 10.6 15.4405V15.885H2.9C1.83916 15.885 1 15.0435 1 13.9961V9.66276C1 8.62977 1.85366 7.77387 2.9 7.77387H8.7H9.7V6.77387C9.7 4.13755 11.8442 1.99609 14.5 1.99609C17.1558 1.99609 19.3 4.13755 19.3 6.77387V7.77387H20.3H26.1C27.1463 7.77387 28 8.62977 28 9.66276V13.9961C28 15.0291 27.1463 15.885 26.1 15.885ZM15.95 20.7739C16.941 20.7739 17.8006 20.181 18.1853 19.3294H26.55V24.1072C26.55 25.1402 25.6963 25.9961 24.65 25.9961H4.3645C3.31816 25.9961 2.4645 25.1402 2.4645 24.1072V19.3294H10.8147C11.1994 20.181 12.059 20.7739 13.05 20.7739H15.95Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export default DirectoryIcon;
