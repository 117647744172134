/** * @module session */

import ExtjsStateManager from "../extjs-state-manager";

const DEFAULT_ATS_BASE = "paris";

/**
 * Session storage object
 *
 * @typedef {Object} SessionStorageType
 *
 * @property {string} atsBase - The ATS base URL retrieved from session storage or default if not available.
 * @property {string} token - The API token retrieved from session storage.
 * @property {string} tokenMedia - The media token retrieved from session storage.
 *
 */

/**
 * Retrieves session storage data including ATS base, API token, and media token.
 *
 * @returns {SessionStorageType} An object containing session storage data: <br>
 *                   - `atsBase`: The ATS base URL retrieved from session storage or default if not available. <br>
 *                   - `token`: The API token retrieved from session storage.<br>
 *                   - `tokenMedia`: The media token retrieved from session storage.
 *
 * @example
 * const sessionData = getSessionStorage();
 * console.log(sessionData);
 * // Output:
 * // {
 * //   atsBase: 'https://example.com/api',
 * //   token: 'abc123xyz',
 * //   tokenMedia: 'mediaToken123'
 * // }
 */
export function getSessionStorage() {
  const storedSession = ExtjsStateManager.get("ext-session");
  return {
    atsBase: storedSession?.ats?.ats_base || DEFAULT_ATS_BASE,
    token: storedSession?.tokenApi?.token,
    tokenMedia: storedSession?.tokenMedia?.token,
  };
}
