import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import { UserProfile } from "routes/pages.import";

export const user = [
  {
    path: "/user-profile",
    element: (
      <ProtectedRoute>
        <UserProfile />
      </ProtectedRoute>
    ),
  },
  {
    path: "/profile/:id",
    element: (
      <ProtectedRoute>
        <UserProfile />
      </ProtectedRoute>
    ),
  },
];
