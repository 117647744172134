import _ from "lodash";
/**
 * @typedef {Object} DataType
 * @property {number|string} id - The unique identifier for the data item, which can be either a number or a string.
 *
 * @param {DataType[]} data - An array of data objects, each containing an `id` property.
 *
 * @returns {Array<number|string>} An array containing the `id` values extracted from the input data array.
 *
 * @example
 * const data = [{ id: 1 }, { id: '2' }, { id: 3 }];
 * const ids = extractIds(data);
 * console.log(ids); // Output: [1, '2', 3]
 */
export function extractIds(data) {
  return data.map((el) => el.id);
}

/**
 * Joins the `name` properties of objects in an array into a single string, separated by commas.
 *
 * @param {Array<{name:string}>} data - An array of objects, each containing a `name` property.
 * @returns {string} A string with all `name` properties joined by a comma and a space.
 *
 * @example
 * const data = [{ name: 'consumer function' }, { name: 'CON01 Digital' }, { name: 'CON03 Operations' }];
 * const result = joinByName(data);
 * console.log(result); // "consumer function, CON01 Digital, CON03 Operations"
 */
export function joinNames(data, key = "name") {
  return _.join(_.map(data, key), ", ");
}

/**
 * Generates a comma-separated string of data' full names.
 *
 * @param {Array<{firstname:string;lastname:string}>} data - An array of researcher objects, each containing `firstname` and `lastname` properties.
 * @returns {string} A comma-separated list of full names if the array is not empty, or "N/C" if the array is empty.
 *
 * @example
 * const data = [
 *   { firstname: 'John', lastname: 'Doe' },
 *   { firstname: 'Jane', lastname: 'Smith' }
 * ];
 * const result = formatDataNames(data);
 * console.log(result); // "John Doe, Jane Smith"
 */
export function joinFirstnameAndLastname(data) {
  return _.join(
    _.map(data, (_el) => `${_el?.firstname} ${_el?.lastname}`),
    ", ",
  );
}

/**
 * Maps an array of objects, extracting the specified key's value from each object.
 *
 * @param {Object[]} items - The array of objects to map over.
 * @param {string} key - The key to extract the value from in each object.
 * @returns {Array} - An array of values corresponding to the specified key in each object.
 */
export function mapValues(items, key) {
  return (items || []).map((item) => item[key]);
}

/**
 * Finds the object with the maximum numerical ID in an array of objects based on a dynamic key.
 *
 * @param {Object[]} arr - The array of objects to search through.
 * @param {string} [idKey='id'] - The key to use for finding the ID in each object.
 * @returns {Object|null} The object with the maximum ID found, or null if the array is empty or invalid.
 */
export function getItemWithMaxIdFromArray(arr, idKey = "id") {
  if (!Array.isArray(arr) || arr.length === 0) {
    return null; // Return null if the array is empty or not valid
  }

  return arr.reduce((maxObj, obj) => {
    if (
      typeof obj[idKey] === "number" &&
      (maxObj === null || obj[idKey] > maxObj[idKey])
    ) {
      return obj;
    }
    return maxObj;
  }, null);
}

/**
 * Generates an array of objects with a dynamic property name and value based on a given key.
 *
 * @param {Object} source - The object to extract the value from.
 * @param {string} key - The key to access the desired value in the object.
 * @param {string} propertyName - The dynamic property name for the resulting object.
 * @returns {Array<Object>} An array with one object if the value exists, otherwise an empty array.
 */
export function generateKeyNameArray(source, key, propertyName) {
  const value = source?.[key];
  return value ? [{ [propertyName]: value }] : [];
}

/**
 * Regroups an array of objects into an object with an array of objects as value, grouped by a dynamic key name.
 *
 * @param {Array<Object>} array - The array of objects to regroup.
 * @param {string} keyName - The key name to group the objects by.
 * @returns {Object} An object with an array of objects as value, grouped by the specified key name.
 */
export function regroupInObjectByKey(array = [], keyName = "") {
  return array.reduce((groups, item) => {
    const key = item[keyName];

    if (key in groups) {
      groups[key].push(item);
    } else {
      groups[key] = [item];
    }
    return groups;
  }, {});
}
