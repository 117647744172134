import React, { useContext } from "react";

import SessionContext from "../../store/session-context";
import styles from "./Profile.module.scss";

const TopProfileImage = () => {
  const sessionCtx = useContext(SessionContext);

  return (
    <span>
      {/*<Figure.Image
        className={styles.image}
        roundedCircle
        width={45}
        height={45}
        src={
          sessionCtx.user.picture_path.little
            ? `${process.env.REACT_APP_MEDIA_URL +
              sessionCtx.user.picture_path.little 
              }&token=${ 
              sessionCtx.tokenMedia.token}`
            : DefaultUserImage
        }
      />*/}
      <span className={`${styles.profilName} d-none d-md-inline-flex`}>
        {`${sessionCtx?.user?.firstname} ${sessionCtx?.user?.lastname}`}
      </span>
    </span>
  );
};

export default TopProfileImage;
