import { ProtectedRoute } from "components/common/ProtectedRoute/ProtectedRoute";
import { Document } from "routes/pages.import";

export const document = [
  {
    path: "/document",
    element: (
      <ProtectedRoute>
        <Document />
      </ProtectedRoute>
    ),
  },
];
