import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsFr from "./translations/fr/translations.json";
import translationsEn from "./translations/en/translations.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      en: {
        translations: translationsEn,
      },
      fr: {
        translations: translationsFr,
      },
    },
  });

export default i18n;
