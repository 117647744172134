import axios from "axios";

import ExtjsStateManager from "utils/extjs-state-manager";
import { showToastOnError } from "utils/helpers/assignment";
import { getApiUrl } from "utils/helpers/configuration";

export const notificationBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {
    try {
      const token = ExtjsStateManager.get("ext-session")?.tokenApi.token;
      const tokenType = { token };
      const apiBaseUrl = baseUrl || getApiUrl();
      const contentType = {};

      const result = await axios({
        url: apiBaseUrl + url,
        method,
        data,
        params,
        headers: {
          Accept: "application/json",
          ...contentType,
          ...tokenType,
        },
      });

      if (
        result.data &&
        result.data["success"] !== undefined &&
        !result.data.success
      ) {
        throw new Error();
      }
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      if (err.code && err.code === "ERR_NETWORK") {
        showToastOnError("Network Error");
      } else if (
        (err.response && err.response?.status === 403) ||
        (err.response && err.response?.status === 401)
      ) {
        window.open(`${window.location.origin}/login`, "_self");
      } else {
        showToastOnError(
          "Oops! We've encountered an error processing your request. Our development team is on it, your concerns are in good hands.",
        );
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
